
import Icon from '@/components/Icon.vue'
import { defineComponent, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
import { keymap } from '@/config'

export default defineComponent({
  name: 'GuideContent',
  components: { Icon, Pic },
  setup () {
    const read = shallowRef(false)
    read.value = Boolean(sessionStorage.getItem(keymap.guide.quickPlayTips))

    const gotIt = () => {
      sessionStorage.setItem(keymap.guide.quickPlayTips, '1')
      read.value = true
    }
    return {
      read,
      gotIt,
    }
  },
})
