
import ChoiceButton from '@/components/ChoiceButton.vue'
import DropDownSelectorView from '@/components/DropDownSelectorView.vue'
import Icon from '@/components/Icon.vue'
import Matrix from 'common/Matrix.vue'
import { computed, defineComponent, ref, watch, watchEffect } from 'vue'

export default defineComponent({
  name: 'TheFiniteSelector',
  components: { Matrix, ChoiceButton, DropDownSelectorView, Icon },
  emits: ['change'],
  props: {
    options: {
      type: Array,
      required: true
    },
    isMoney: {
      type: Boolean,
      default: true
    },
    createWithVoucher: Boolean
  },
  setup(props, ctx) {
    const max = computed(() => props.options.filter((x: any) => !x?.disable).length)
    const current = ref(0)
    const value = computed(() => props.options[current.value])

    const next = (dir: 1 | -1) => {
      const next = current.value + dir * 1
      const _max = max.value
      current.value = (next + _max) % _max
    }

    const emitVal = () => {
      ctx.emit('change', value.value)
    }

    watch(
      () => max.value,
      v => {
        if (v) {
          current.value = current.value > v - 1 ? v - 1 : current.value
        }
      },
      { immediate: true }
    )

    watch(() => current.value, emitVal, { immediate: true })

    return {
      next,
      select(index: number) {
        current.value = index
      },
      value
    }
  }
})
