
import { positionsCount } from '@/modules/fastTrade/fastTrade'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ThePositionCountBadge',
  setup () {
    return {
      positionsCount,
    }
  },
})
