
import TheOrderItem from '@/modules/fastTrade/components/TheOrderItem.vue'
import { defineComponent, computed, onActivated } from 'vue'
import Scroller from 'common/providers/Scroller.vue'
import Holder from '@/provider/Holder.vue'
import useLoadMore from 'common/hooks/useLoadMore'
import { FastOrderSchema, readFastHistorySp } from '@/modules/fastTrade/history.api'
import TheFastHistoryContent from '@/modules/fastTrade/components/TheFastHistoryContent.vue'
import TheFastHistoryHeader from '@/modules/fastTrade/components/TheFastHistoryHeader.vue'
import { formatDateTime } from '@/common/format'
import * as R from 'ramda'
import { defaultToArray } from '@/common/common'
import Nullable from '@/components/Nullable.vue'

export default defineComponent({
  name: 'History',
  components: {
    Nullable,
    TheFastHistoryHeader,
    TheFastHistoryContent,
    TheOrderItem,
    Holder,
    Scroller
  },
  props: {
    code: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { loaded: history, refresh, loadMore } = useLoadMore<
      FastOrderSchema,
      { orders: Array<FastOrderSchema> }
    >(
      () => readFastHistorySp({ dateType: 0, infoType: 0, contract: props.code }),
      20,
      resp => resp.orders
    )

    const today = formatDateTime('MM-dd-yy', new Date().getTime())

    refresh()

    const groupHistory: (orders: FastOrderSchema[]) => Record<string, FastOrderSchema[]> = R.pipe(
      defaultToArray,
      R.map((order: FastOrderSchema) =>
        R.assoc('day', formatDateTime('MM-dd-yy', R.prop('createTime', order)), order)
      ),
      R.groupBy(R.prop('day'))
    )

    const historyGroup = computed((): Record<'day', FastOrderSchema[]> | null => {
      if (history.value) return groupHistory(history.value)
      return null
    })

    onActivated(refresh)

    return {
      loadMore,
      today,
      historyGroup
    }
  }
})
