
import TheOrderItem from '@/modules/fastTrade/components/TheOrderItem.vue'
import { defineComponent, onActivated, watchEffect, onBeforeUnmount } from 'vue'
import Scroller from 'common/providers/Scroller.vue'
import Holder from '@/provider/Holder.vue'
import useLoadMore from 'common/hooks/useLoadMore'
import { FastOrderSchema, readFastPositionSp } from '@/modules/fastTrade/history.api'
import TheFastPositionsContent from '@/modules/fastTrade/components/TheFastPositionsContent.vue'
import TheFastPositionsHeader from '@/modules/fastTrade/components/TheFastPositionsHeader.vue'
import List from '@/components/AsyncNullableList.vue'
import { updatePositionsCount } from '@/modules/fastTrade/fastTrade'
import { off, on } from 'essential/tools/event'
import { events } from '@/config'

export default defineComponent({
  name: 'Positions',
  components: {
    List,
    TheFastPositionsHeader,
    TheFastPositionsContent,
    TheOrderItem,
    Holder,
    Scroller
  },
  props: {
    code: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { loaded, refresh, loadMore } = useLoadMore<FastOrderSchema, Array<FastOrderSchema>>(
      () => readFastPositionSp({ contract: props.code }),
      10,
      resp => resp
    )

    onActivated(refresh)

    watchEffect(() => updatePositionsCount(loaded.value?.length || 0))

    on(events.quickClose, refresh)

    onBeforeUnmount(() => {
      off(events.quickClose, refresh)
    })

    return {
      loadMore,
      loaded
    }
  }
})
