/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/18 10:43
 * @description
 *   fastTrade.api.ts of WeTrade
 */
import requestResult, { requestByAccountType, requestResultSpeed } from '@/common/request/request'
import withAccountUpdate from '@/decorators/withAccountUpdate'
import withoutCryptos from '@/decorators/withoutCryptos'
import { FastOrderSchema } from '@/modules/fastTrade/history.api'

export interface ProductSchema {
  contract: string
  prices: string
  profitRate: number
  stopLossPoint: string
  stopProfitPoint: string
  pointStep: string
  calculatePoint: number
  contractVolume: number
  amounts: string[]
}

export const readProducts = withoutCryptos(
  requestResult<ProductSchema[]>('/api/product/new/option/list'),
  'contract'
)

export const create = withAccountUpdate(requestByAccountType<FastOrderSchema>('fastCreate'))

export const createSp = withAccountUpdate(
  requestResultSpeed<FastOrderSchema>('/web/trade/quick/create', 1)
)

export type CreditList = {
  amount: string
}
export type CreditSchema = {
  creditShowStatus: number // 1显示 0 不显示
  creditMaxTradeAmt: string
  possibleList: CreditList[]
  impossibleList: CreditList[]
}
export const readCredit = requestResultSpeed<CreditSchema>('/web/trade/quick/voucher/list')
