import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { style: {"margin":"0 100px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheFastHistoryHeader = _resolveComponent("TheFastHistoryHeader")
  const _component_TheFastHistoryContent = _resolveComponent("TheFastHistoryContent")
  const _component_TheOrderItem = _resolveComponent("TheOrderItem")
  const _component_Nullable = _resolveComponent("Nullable")
  const _component_Holder = _resolveComponent("Holder")
  const _component_Scroller = _resolveComponent("Scroller")

  return (_openBlock(), _createBlock(_component_Scroller, {
    onBottomTouched: _ctx.loadMore,
    class: "spread px-16 pt-6 mb-8"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Holder, {
        content: _ctx.historyGroup,
        "skeleton-repeat": 2
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_1, [
            _createVNode(_component_Nullable, { content: _ctx.historyGroup }, {
              default: _withCtx(() => [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.historyGroup, (list, date) => {
                  return (_openBlock(), _createBlock(_Fragment, null, [
                    (date !== _ctx.today)
                      ? (_openBlock(), _createBlock("div", {
                          key: date,
                          class: "t-center my-12 f-md c-tip"
                        }, " -- " + _toDisplayString(date) + " -- ", 1))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(list, (item) => {
                      return (_openBlock(), _createBlock(_component_TheOrderItem, {
                        key: item.id
                      }, {
                        header: _withCtx(() => [
                          _createVNode(_component_TheFastHistoryHeader, { order: item }, null, 8, ["order"])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_TheFastHistoryContent, { order: item }, null, 8, ["order"])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ], 64))
                }), 256))
              ]),
              _: 1
            }, 8, ["content"])
          ])
        ]),
        _: 1
      }, 8, ["content"])
    ]),
    _: 1
  }, 8, ["onBottomTouched"]))
}