
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'DropDownSelectorView',
  props: {
    limitHeight: String,
  },
  setup () {
    const expand = shallowRef(false)

    return {
      expand,
    }
  },
})
