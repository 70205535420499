
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ThePriceLabel',
  props: {
    price: String,
    position: Object,
  },
})
