
import { TradeDirection } from '@/types'
import { add } from 'essential/tools/math'
import { computed, defineComponent, shallowRef, watchEffect } from 'vue'

export default defineComponent({
  name: 'TheStopLines',
  props: {
    price: String,
    stops: {
      type: Object,
    },
    type: Number,
    fy: {
      type: Function,
      required: true,
    },
  },
  setup (props) {
    const profitPosition = shallowRef(0)
    const profitPrice = shallowRef(0)
    const lossPosition = shallowRef(0)
    const lossPrice = shallowRef(0)
    const stops = computed(() => props.stops)
    const factor = computed(() => props.type === TradeDirection.BUY ? 1 : -1)

    watchEffect(() => {
      if (!props.price) return
      if (!stops.value) return
      profitPrice.value = add(props.price, stops.value.profit * factor.value)
      lossPrice.value = add(props.price, -stops.value.loss * factor.value)
      profitPosition.value = props.fy(profitPrice.value)
      lossPosition.value = props.fy(lossPrice.value)
    })

    return {
      profitPosition,
      lossPosition,
      profitPrice,
      lossPrice,
    }
  },
})
