
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RatioFixedArea',
  inheritAttrs: false,
  props: {
    ratio: {
      type: Number,
      required: true,
    },
  },
})
