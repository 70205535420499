
import { marketFeed } from '@/common/datafeed'
import ChoiceButton from '@/components/ChoiceButton.vue'
import DropDownSelectorView from '@/components/DropDownSelectorView.vue'
import Icon from '@/components/Icon.vue'
import { keymap } from '@/config'
import { ProductSchema } from '@/modules/fastTrade/fastTrade.api'
import CurrentQuote from '@/provider/CurrentQuote.vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import Matrix from 'common/Matrix.vue'
import { localGet } from 'essential/store/localStore'
import * as R from 'ramda'
import { defineComponent, shallowRef, toRaw, onMounted } from 'vue'

export default defineComponent({
  name: 'TheSymbolSelector',
  components: { DropDownSelectorView, CurrentQuote, ChoiceButton, Matrix, Icon, RealTimeQuote },
  props: {
    type: Number,
    code: {
      type: String
    }
  },
  emits: ['change'],
  setup(props, ctx) {
    const isExpand = shallowRef(false)
    const products = shallowRef<ProductSchema[]>()
    const product = shallowRef<ProductSchema | null>(null)

    const changeTab = (it: ProductSchema) => {
      if (it) {
        product.value = it
        ctx.emit('change', toRaw(product.value))
      }
    }

    onMounted(() => {
      const d = JSON.parse(localGet(keymap.speed.quicklist) as string) as ProductSchema[]
      if (d) {
        products.value = d
        changeTab(R.find(R.propEq('contract', props.code ?? ''), d) ?? d[0])
        marketFeed.subscribe(R.pluck('contract', d))
      }
    })

    return {
      products,
      product,
      isExpand,
      changeTab
    }
  }
})
