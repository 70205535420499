
import { defineComponent, ref } from 'vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'TheOrderItem',
  components: { Icon },
  setup () {
    const show = ref(false)

    const isShow = () => {
      show.value = !(show.value)
    }

    return {
      show,
      isShow,
    }
  },
})
