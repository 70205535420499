import requestResult, { requestByAccountType, requestResultSpeed } from '@/common/request/request'
import { TradeDirection, YesOrNo } from '@/types'

export type FastOrderSchema = {
  orderId: number;
  id: number;
  contactCode: string;
  contract: string;
  number: number;
  amount: number;
  buyType: TradeDirection;
  closeType: number; // 3：手动平仓 4：止盈平仓 5：止损平仓 6：爆仓
  fee: number;
  createTime: number;
  closeTime: number;
  profit: number;
  stopLossPrice: number;
  stopProfitPrice: number;
  voucher: YesOrNo;
  unit: string;
  weight: string;
  result: number;
  closePrice: number;
  createPrice: number;
}

export const readFastHistory = requestByAccountType<Array<FastOrderSchema>>('fastHistory')

export const readFastHistorySp = requestResultSpeed<{ orders: Array<FastOrderSchema> }>('/web/trade/quick/history/list')

export const readFastPositions = requestByAccountType<Array<FastOrderSchema>>('fastPositions')

export const readFastPositionSp = requestResultSpeed<Array<FastOrderSchema>>('/web/trade/quick/position/list')

export const entryFastAuth = requestResult('/api/order/new/option/isauth')
