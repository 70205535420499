
import { defineComponent } from 'vue'
import Money from '@/components.global/Money'
import ColorText from '@/components/ColorText.vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheFastHistoryHeader',
  components: { Money, ColorText, Pic },
  props: {
    order: {
      type: Object,
      required: true
    }
  }
})
