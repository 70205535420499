import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"margin":"0 100px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheFastPositionsHeader = _resolveComponent("TheFastPositionsHeader")
  const _component_TheFastPositionsContent = _resolveComponent("TheFastPositionsContent")
  const _component_TheOrderItem = _resolveComponent("TheOrderItem")
  const _component_List = _resolveComponent("List")
  const _component_Holder = _resolveComponent("Holder")
  const _component_Scroller = _resolveComponent("Scroller")

  return (_openBlock(), _createBlock(_component_Scroller, {
    onBottomTouched: _ctx.loadMore,
    class: "spread px-16 pt-6 mb-8"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Holder, {
        content: _ctx.loaded,
        "skeleton-repeat": 2
      }, {
        default: _withCtx(() => [
          _createVNode(_component_List, { "list-data": _ctx.loaded }, {
            default: _withCtx(({ item }) => [
              _createVNode("div", _hoisted_1, [
                _createVNode(_component_TheOrderItem, null, {
                  header: _withCtx(() => [
                    _createVNode(_component_TheFastPositionsHeader, { order: item }, null, 8, ["order"])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_TheFastPositionsContent, { order: item }, null, 8, ["order"])
                  ]),
                  _: 2
                }, 1024)
              ])
            ]),
            _: 1
          }, 8, ["list-data"])
        ]),
        _: 1
      }, 8, ["content"])
    ]),
    _: 1
  }, 8, ["onBottomTouched"]))
}