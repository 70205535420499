
import Radio from '@/components/Radio.vue'
import Icon from '@/components/Icon.vue'
import TheAsset from '@/modules/user/components/TheAsset.vue'
import { defineComponent, ref, watch } from 'vue'
import { getCookie, getSpeedAc, setCookie } from '@/common/goSpeed'

export default defineComponent({
  name: 'TheTradeMethod',
  components: {
    Radio,
    Icon,
    TheAsset
  },
  props: {
    credit: Number,
    uncredit: Number,
    show: Boolean
  },
  emits: ['update:method'],
  setup(props, ctx) {
    const method = ref(0)
    const k = 'credit_showtips'
    const uuid = getSpeedAc('uuid')
    const showTip = ref(!getCookie(k) || getCookie('uuid') !== uuid)
    const methodLocal = ref(0)

    const choseId = (id: number) => {
      method.value = id
      methodLocal.value = id
      ctx.emit('update:method', id)
    }
    const setTips = () => {
      setCookie(k, 'asd1sf2123')
      setCookie('uuid', uuid)
      showTip.value = false
    }

    watch(() => props.show, () => {
      if (!props.show) {
        method.value = 0
        ctx.emit('update:method', 0)
      } else {
        choseId(methodLocal.value)
      }
    })

    return {
      method,
      showTip,
      setTips,
      choseId
    }
  }
})
