import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheStopLines = _resolveComponent("TheStopLines")
  const _component_ThePriceLabel = _resolveComponent("ThePriceLabel")
  const _component_RatioFixedArea = _resolveComponent("RatioFixedArea")

  return (_openBlock(), _createBlock(_component_RatioFixedArea, {
    ratio: 0.6,
    style: {"overflow":"hidden"}
  }, {
    default: _withCtx(() => [
      _createVNode("div", {
        ref: "chart",
        class: "fill",
        style: { opacity: _ctx.show ? '1' : '0' }
      }, [
        (_ctx.hideLine)
          ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_TheStopLines, {
                price: _ctx.price,
                type: _ctx.type,
                stops: _ctx.stops,
                fy: _ctx.fy
              }, null, 8, ["price", "type", "stops", "fy"]),
              _createVNode(_component_ThePriceLabel, {
                price: _ctx.price,
                position: _ctx.position
              }, null, 8, ["price", "position"])
            ], 64))
          : _createCommentVNode("", true)
      ], 4)
    ]),
    _: 1
  }, 8, ["ratio"]))
}