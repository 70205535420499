
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import Line from '@/components/Line.vue'
import { showAlert } from '@/components/popup/popup'
import useRequest from '@/hooks/useRequest'
import { translate } from '@/i18n'
import TheFiniteSelector from '@/modules/fastTrade/components/TheFiniteSelector.vue'
import TheRowTemplate from '@/modules/fastTrade/components/TheRowTemplate.vue'
import { Product } from '@/modules/fastTrade/fastTrade'
import { createSp, CreditList, readCredit } from '@/modules/fastTrade/fastTrade.api'
import CurrentQuote from '@/provider/CurrentQuote.vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import { TradeDirection } from '@/types'
import { add } from 'essential/tools/math'
import { computed, defineComponent, shallowRef, watchEffect, watch, ref, onBeforeUnmount } from 'vue'
import TheTradeMethod from './TheTradeMethod.vue'
import * as R from 'ramda'
import { off, on } from 'essential/tools/event'
import { events } from '@/config'

export default defineComponent({
  name: 'TheTradePanel',
  components: {
    Button,
    RealTimeQuote,
    CurrentQuote,
    TheFiniteSelector,
    TheRowTemplate,
    Icon,
    Line,
    TheTradeMethod
  },
  props: {
    list: {
      type: Array
    },
    code: String
  },
  emits: ['created', 'typeChanged'],
  setup(props, ctx) {
    const type = shallowRef(TradeDirection.BUY)
    const product = shallowRef<Product | null>(null)
    const products = shallowRef<Product[]>([])
    const productscredit = shallowRef<Product[]>([])
    const paymethod = ref(0)
    const showCredit = ref<{ show: boolean; m: number; d: number; max: number }>()
    const isBuy = computed(() => type.value === TradeDirection.BUY)

    const buy = () => {
      type.value = TradeDirection.BUY
    }

    const sell = () => {
      type.value = TradeDirection.SELL
    }

    watchEffect(() => {
      ctx.emit('typeChanged', type.value)
    })

    const updateShowCredit = () => {
      readCredit({ contract: props.code }).then(res => {
        const { creditShowStatus, creditMaxTradeAmt, possibleList, impossibleList } = res
        showCredit.value = {
          show: creditShowStatus === 1,
          m: addM(possibleList),
          d: addM(impossibleList),
          max: Number(creditMaxTradeAmt) || 999999999
        }
        productscredit.value = products.value.map(x => ({ ...x, disable: Number(x.price) > (showCredit.value ? showCredit.value.max : 999999999) }))
      })
    }

    watch(
      () => props.list,
      v => {
        if (v?.length) {
          const d = v as Product[]
          products.value = d
          updateShowCredit()
        }
      },
      { immediate: true }
    )

    const [_commit, progress] = useRequest(createSp)

    const commit = () => {
      return _commit({
        contract: product.value?.code,
        amount: product.value?.price,
        type: type.value,
        ...(paymethod.value ? { isJuan: 1, voucherIds: 2 } : null)
      })
        .then(resp => {
          showAlert(translate('quickplay_16'))
          ctx.emit('created', resp)
          updateShowCredit()
        })
        .catch((err) => {
          showAlert(err.m || 'You can\'t use credit for trading right now')
        })
    }

    const calcStopPrice = (price: string, profit = false) => {
      const diff = profit ? product.value?.stopProfitOffset : -(product.value?.stopLossOffset ?? 0)
      return add(price, (diff || 0) * (isBuy.value ? 1 : -1))
    }

    const addM = (a: CreditList[]) =>
      R.reduce(R.add, 0, R.map(x => Number(x?.amount), a) as number[])

    on(events.usecredit, updateShowCredit)
    onBeforeUnmount(() => {
      off(events.usecredit, updateShowCredit)
    })

    return {
      Button,
      isBuy,
      buy,
      sell,
      product,
      products,
      productscredit,
      commit,
      progress,
      paymethod,
      calcStopPrice,
      showCredit
    }
  }
})
