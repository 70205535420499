
import T from '@/components.global/T.vue'
import Icon from '@/components/Icon.vue'
import Line from '@/components/Line.vue'
// import { openDialog } from '@/components/popup/popup'
import WTTabs from '@/components/Tabs.vue'
import { events } from '@/config'
// import { refreshAccount } from '@/modules/app/app'
import TheChart from '@/modules/fastTrade/components/TheChart.vue'
// import TheGuideDialog from '@/modules/fastTrade/components/TheGuideDialog.vue'
import ThePositionCountBadge from '@/modules/fastTrade/components/ThePositionCountBadge.vue'
import TheSymbolSelector from '@/modules/fastTrade/components/TheSymbolSelector.vue'
import TheTradePanel from '@/modules/fastTrade/components/TheTradePanel.vue'
import TheFastPlayTips from '@/modules/fastTrade/components/TheFastPlayTips.vue'
import {
  calcStops,
  dropUnusedOrderColor,
  generateProducts,
  Product,
  updatePositionsCount
} from '@/modules/fastTrade/fastTrade'
import { ProductSchema } from '@/modules/fastTrade/fastTrade.api'
import { FastOrderSchema, readFastPositionSp } from '@/modules/fastTrade/history.api'
import History from '@/modules/fastTrade/History.vue'
import Positions from '@/modules/fastTrade/Positions.vue'
import TheAsset from '@/modules/user/components/TheAsset.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { TradeDirection } from '@/types'
import DemoAccountTip from '@/views/DemoAccountTip.vue'
// import { localGet, localSet } from 'essential/store/localStore'
import { off, on } from 'essential/tools/event'
import * as R from 'ramda'
import { defineComponent, h, shallowRef, watchEffect, ref, onBeforeUnmount } from 'vue'

export default defineComponent({
  name: 'FastTrade',
  components: {
    Icon,
    Line,
    History,
    Positions,
    TheTradePanel,
    WTTabs,
    DemoAccountTip,
    TheChart,
    TheSymbolSelector,
    TheAsset,
    PageWithHeader,
    TheFastPlayTips
  },
  props: {
    c: String
  },
  setup(props) {
    const positions = shallowRef<FastOrderSchema[]>([]) // 记录持仓
    const actKey = ref('')
    const code = ref(props.c) // symbol
    const stops = shallowRef<{ profit: number; loss: number } | null>(null) // 止盈/止损范围
    const tradeType = shallowRef(TradeDirection.BUY) // 做单方向
    const products = shallowRef<Product[]>([])
    const positionTab = h('span', [h(T, { path: 'quickplay_5' }), h(ThePositionCountBadge)])

    // 更新当前品种的持仓单
    const updateHoldPositions = () => {
      readFastPositionSp({ contract: code.value }).then(resp => {
        positions.value = R.filter(R.propEq('contract', code.value), resp || [])
        // 去除多余的订单颜色信息
        dropUnusedOrderColor(R.pluck('orderId', resp || []))
      })
    }

    watchEffect(() => {
      updatePositionsCount(positions.value.length)
    })

    // const showGuide = () => {
    //   openDialog(TheGuideDialog)
    // }

    // if (!localGet(keymap.guide.fastGuideDialog)) {
    //   showGuide()
    //   localSet(keymap.guide.fastGuideDialog, '1')
    // }

    const onCreated = () => {
      updateHoldPositions()
    }

    const onProductChange = (product: ProductSchema) => {
      code.value = product.contract
      products.value = generateProducts(product)
      stops.value = calcStops(product)
      updateHoldPositions()
    }

    const onTypeChange = (_type: TradeDirection) => {
      tradeType.value = _type
    }

    on(events.quickClose, onCreated)
    onBeforeUnmount(() => {
      off(events.quickClose, onCreated)
    })

    return {
      positionTab,
      products,
      positions,
      code,
      actKey,
      stops,
      tradeType,
      onCreated,
      onProductChange,
      onTypeChange
    }
  }
})
